<script setup>
defineProps( {
    value: {
        type: Number,
        required: true,
    }
} )

const prefix = (value) => {
    return ( value > 0 ? '+' : '' ) + value
}
</script>

<template>
    <span :class="[value > 0 ? 'bg-gradient-to-r from-primary-400 to-primary-500 text-transparent bg-clip-text' : value < 0 ? 'text-rose-500' : 'text-gray-400', 'font-bold inline-block']">
        {{ prefix( value ) }}%
    </span>
</template>
