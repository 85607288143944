<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import Section from '@/Components/Section.vue';
import Heading from '@/Components/Heading.vue';
import Status from '@/Pages/Dashboard/Partials/Employees/Status.vue';
import Change from '@/Components/Change.vue';
import Loader from '@/Components/Loader.vue';
import { chunks } from "@/Components/Composable/Chunk";

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const props = defineProps( {
    profile: Object,
    interval: Number,
    small: Boolean,
} );

// Set employees list
const employees = ref( [] );
const average = ref( '0' );

// Load employees when mounting and subscribe to websocket
onMounted( () => {
    loadEmployees();

    // Interval for every 5 minutes
    setInterval(() => {
        loadEmployees();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.employees', (data) => {
        if (data?.hasChanged) {
            loadEmployees();
        }
    } );
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.employees' );
} )

/**
 * Load employees from API
 */
const loadEmployees = () => {
    axios.get( `/api/${props.profile.slug}/employees` ).then( (response) => {
        const { data } = response;

        employees.value = data.employees;
        average.value = data.average;
    } );
}

// Chunk employees for slider
const employeeChunks = computed( () => {
    return chunks( employees.value, props.small ? 4 : 5 )
} )

// Calculate percentage difference
const calculatePercentageImprovement = (a, b) => {
    if (b === 0) {
        return 0;
    }

    let improvement = ((a - b) / b) * 100;
    return Math.round(improvement);
}

// Define sizes for small layout
const columnSize = props.small ? 'pt-1.5 pb-3.5' : ' py-2.5';
</script>

<template>
    <Section>
        <Heading title="Productiviteit" :subtitle="`gemiddeld ${average}%`" class="mb-4"/>

        <div class="flex grow" v-if="employees.length > 0">
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :pagination="{ clickable: true }"
                :grabCursor="true"
                :virtual="true"
                :loop="true"
                :modules="[Pagination, Virtual]">
                <swiper-slide
                    v-for="(chunk, index) in employeeChunks"
                    :key="index"
                    :virtualIndex="index">
                    <table class="min-w-full">
                        <thead>
                            <tr class="text-xs text-left text-gray-400 font-semibold">
                                <th scope="col" v-if="!small">Teamlid</th>
                                <th scope="col">Productiviteit</th>
                                <th scope="col">Target</th>
                                <th scope="col">Gemiddeld</th>
                                <th scope="col">Vorig jaar</th>
                            </tr>
                        </thead>
                        <tbody v-if="chunk.length">
                            <template v-for="(employee, index) in chunk" :key="index">
                                <tr v-if="small">
                                    <td class="whitespace-nowrap pt-2.5" colspan="4">
                                        <div class="flex items-center">
                                            <div class="mr-2">
                                                <img class="w-9 h-9 rounded-full overflow-hidden object-cover" width="30" height="30" :src="employee.avatar" :alt="employee.name"/>
                                            </div>
                                            <div class="flex flex-col">
                                                <h3 class="text-base font-semibold">{{ employee.name }}</h3>
                                                <span class="text-xs">{{ employee.function }}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="whitespace-nowrap" :class="columnSize" v-if="!small">
                                        <div class="flex items-center">
                                            <div class="mr-2">
                                                <img class="w-12 h-12 rounded-full overflow-hidden object-cover" width="48" height="48" :src="employee.avatar" :alt="employee.name"/>
                                            </div>
                                            <div class="flex flex-col">
                                                <h3 class="text-base font-semibold">{{ employee.name }}</h3>
                                                <span class="text-xs">{{ employee.function }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap" :class="columnSize">
                                        <div class="flex items-center">
                                            <Status :status="employee.declarability.overal >= employee.declarability.target" class="mr-1"/>
                                            <span class="text-xs font-bold">{{ employee.declarability.overal }}%</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap" :class="columnSize">
                                        <span class="flex items-center text-xs">{{ employee.declarability.target }}%</span>
                                    </td>
                                    <td class="whitespace-nowrap" :class="columnSize">
                                        <span class="flex items-center text-xs">{{ employee.declarability.average }}% &nbsp;<Change :value="calculatePercentageImprovement(employee.declarability.average, employee.declarability.target)"/></span>
                                    </td>
                                    <td class="whitespace-nowrap" :class="columnSize">
                                        <span class="flex items-center text-xs">{{ employee.declarability.last_year }}% &nbsp;<Change :value="calculatePercentageImprovement(employee.declarability.average, employee.declarability.last_year)"/></span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </swiper-slide>
            </swiper>
        </div>
        <Loader v-else/>
    </Section>
</template>
